<app-modal
	[title]="confirmationModalTitle | translate"
	#confirmModal
	[showModal]="confirmationModalState"
	modalWidth="40"
	(closeModalFrom2NDParent)="closeConfirmModal()">
	<div class="col-md-12 col-xs-12 col-sm-12 text-center" *ngIf="confirmationModalState">
		<div class="col-md-12 col-sm-12 col-xs-12 clear-padding" style="margin-top: 30px">
			<label class="margin-15-b">
				{{ "CONFIRM_DIALOG" | translate }}
			</label>
		</div>

		<div
			class="col-md-12 col-sm-12 col-xs-12 text-center login-form"
			style="margin-bottom: 6%; padding: 0px !important">
			<button (click)="actionConfirmModal()">
				{{ "CONTINUE" | translate }}
			</button>
		</div>
	</div>
</app-modal>

<app-modal
	#cancelAdminModal
	[title]="'CANCEL' | translate"
	[showModal]="showCancelAdmin"
	modalWidth="40"
	(buttonClicked)="closeCancelAdminModal()"
	(closeModalFrom2NDParent)="closeCancelAdminModal()">
	<div *ngIf="showCancelAdmin">
		<div style="padding: 15px 54px; margin-bottom: 20px">
			<label>
				{{ "ETICKET" | translate }}
			</label>
			<br />
			<app-shared-commun-dropdown
				[mainButtonID]="'eticket_list'"
				[mainButtonText]="eTicketList[0]"
				[dropdownAriaID]="'eticket_list_dropdown_aria'"
				[ariaButtonsID]="eTicketList"
				[ariaButtonsText]="eTicketList"
				(clickEvent)="selectEticketToCancel($event)"
				[useShowDropdownMenu]="true">
			</app-shared-commun-dropdown>
		</div>

		<div
			class="col-md-12 col-sm-12 col-xs-12 login-form clear-padding"
			style="padding: 15px 54px; margin-bottom: 20px">
			<label>{{ "AMOUNT_TO_REFUND" | translate }}&nbsp;<b style="color: crimson">*</b></label>
			<div class="input-group" style="width: 100%">
				<input
					type="text"
					name="amount_to_refund"
					class="form-control select-add-style"
					[(ngModel)]="cancelRefundAmount"
					id="cancel_refund_amount"
					#amount_to_refund="ngModel"
					pattern="[0-9]{1,}"
					required />
				<p
					class="failed padding-15-t"
					*ngIf="amount_to_refund.invalid && amount_to_refund.touched">
					{{ "INVALID" | translate }}
				</p>
			</div>

			<label>{{ "SERVICE_FEE" | translate }}&nbsp;<b style="color: crimson">*</b></label>
			<div class="input-group" style="width: 100%">
				<input
					type="text"
					name="cancel_fee"
					class="form-control select-add-style"
					[(ngModel)]="cancelFee"
					id="cancel_admin_cancel_fee"
					#cancel_fee="ngModel"
					pattern="[0-9]{1,}"
					required />
				<p class="failed padding-15-t" *ngIf="cancel_fee.invalid && cancel_fee.touched">
					{{ "INVALID" | translate }}
				</p>
				<p class="failed padding-15-t" *ngIf="cancelFee > cancelRefundAmount">
					{{ "CANCEL_FEES_AMOUNT_ERROR" | translate }}
				</p>
			</div>

			<button
				type="submit"
				style="margin-top: 20px; float: left"
				id="cancel_button"
				(click)="closeCancelAdminModal()">
				{{ "CANCEL" | translate }}
			</button>

			<button
				type="submit"
				style="margin-top: 20px; margin-right: 15px; float: right"
				id="update_button"
				(click)="openConfirmModal('CANCEL_ADMIN_PENALTY', cancelAdminModal)"
				[disabled]="
					amount_to_refund.invalid ||
					cancel_fee.invalid ||
					cancelEticketNumber === undefined ||
					cancelFee > cancelRefundAmount
				">
				{{ "MAJ" | translate }}
			</button>
		</div>
	</div>
</app-modal>

<app-modal
	#eTicketEmailModal
	[title]="'SEND-BY-EMAIL' | translate"
	[showModal]="showModalEmail"
	modalWidth="30"
	(buttonClicked)="showModalEmail = false"
	(closeModalFrom2NDParent)="showModalEmail = false">
	<div class="text-center" *ngIf="showModalEmail">
		<div class="col-md-12 col-xs-12 col-sm-12 text-center login-form" style="padding: 0px 45px">
			<label>{{ "EMAIL" | translate }}&nbsp;<b style="color: crimson">*</b></label>
			<div class="input-group" style="width: 100%">
				<input
					class="form-control select-add-style"
					type="email"
					name="client_email"
					[(ngModel)]="printEmail"
					id="flight_passeneger_print_client_email_input"
					#client_email="ngModel"
					pattern="[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$"
					required />
				<p class="failed" *ngIf="client_email.invalid && client_email.touched">
					{{ "INVALID" | translate }}
					{{ "EMAIL" | translate }}
				</p>
			</div>
		</div>

		<div
			class="col-md-12 col-sm-12 col-xs-12 text-center login-form"
			style="padding: 0 !important"
			*ngIf="sendB2C == 0">
			<button
				type="submit"
				style="margin-bottom: 6%; margin-top: 0px !important"
				[disabled]="!printEmail || printEmail == ''"
				id="flight_passenger_email_continue_button"
				(click)="sendBookingByEmail(modalElement.booking.ticket, eTicketEmailModal)">
				{{ "CONTINUE" | translate }}
			</button>
		</div>

		<div
			class="col-md-12 col-sm-12 col-xs-12 text-center login-form"
			style="padding: 0 !important"
			*ngIf="sendB2C == 1">
			<button
				type="submit"
				style="margin-bottom: 6%; margin-top: 0px !important"
				(click)="sendReceipt()">
				{{ "CONTINUE" | translate }}
			</button>
		</div>
	</div>
</app-modal>

<app-modal
	[title]="'HISTORY' | translate"
	#eTicketHistoryModal
	[showModal]="showModalHistory"
	modalWidth="70"
	modalHeight="80"
	(closeModalFrom2NDParent)="showModalHistory = false">
	<div
		class="col-md-12 col-xs-12 col-sm-12 text-center"
		style="margin-top: 2%"
		*ngIf="showModalHistory">
		<table class="table table-striped" style="width: 100%; border: 1px solid #eee">
			<thead>
				<tr>
					<th class="uppercase-text">{{ "DATES" | translate }}</th>
					<th class="uppercase-text">{{ "PAX_TYPE" | translate }}</th>
					<th class="uppercase-text">{{ "AGENT" | translate }}</th>
					<th class="uppercase-text">{{ "PRICE" | translate }}</th>
					<th class="uppercase-text">{{ "REFUND" | translate }}</th>
				</tr>
			</thead>

			<tbody>
				<tr
					*ngFor="
						let history of modalElement.booking.ticket.history.slice(
							paginationStart,
							paginationEnd
						)
					">
					<td>{{ history["date"] }}</td>
					<td>{{ history["type"] }}</td>
					<td>{{ history["agent_name"] }}</td>

					<td *ngIf="history['price']; else empty">
						{{ formatFlightPrice(history["price"]["total"]) }}
						{{ history["price"]["currency"] }}
					</td>

					<td *ngIf="history['refund']; else empty">
						{{ formatFlightPrice(history["refund"]["total"]) }}
						{{ history["refund"]["currency"] }}
					</td>

					<ng-template #empty>
						<td>&nbsp;</td>
					</ng-template>
				</tr>
			</tbody>
		</table>
	</div>

	<div
		class="c_pagination clearfix padd-120"
		*ngIf="showModalHistory && modalElement.booking.ticket.history.length > 0">
		<div
			class="color-4 col-md-4 col-sm-6 col-xs-12 flight-manage-bookings"
			style="margin-top: 8px">
			<ngb-pagination
				[collectionSize]="getPaginationSize()"
				[(page)]="page"
				[maxSize]="1"
				[rotate]="true"
				[boundaryLinks]="true"
				(pageChange)="changePage()"
				id="flight_eticket_history_pagination">
			</ngb-pagination>
		</div>

		<div
			class="color-4 col-md-2 col-sm-6 col-xs-12 fr clear-padding"
			style="margin-right: 15px">
			<label class="margin-15-b">
				{{ "ITEM_PER_PAGE" | translate }}
			</label>
			<br />
			<app-shared-commun-dropdown
				[mainButtonID]="'flight_manage_markups_dropdown'"
				[mainButtonText]="classDropDownText[0]"
				[dropdownAriaID]="'flight_manage_markups_dropdown_aria'"
				[ariaButtonsID]="classDropDownID"
				[ariaButtonsText]="classDropDownText"
				(clickEvent)="setItemPerpage($event)"
				[useShowDropdownMenu]="true">
			</app-shared-commun-dropdown>
		</div>
	</div>
</app-modal>

<app-modal
	[title]="'CRYPTIC'"
	#crypticModal
	[showModal]="showCrypticModal"
	modalWidth="70"
	modalHeight="70"
	(closeModalFrom2NDParent)="showCrypticModal = false">
	<code
		class="col-md-12 col-xs-12 col-sm-12 cryptic_modal"
		*ngIf="crypticCode"
		[innerHTML]="crypticCode">
	</code>

	<div *ngIf="crypticCode === undefined" class="col-md-12 col-xs-12 col-sm-12 text-center">
		<b>{{ "CRYPTIC_NOT_AVAILABLE" | translate }}</b>
	</div>
</app-modal>

<app-modal
	title="{{ 'CONDITIONS' | translate }}"
	#flightAvailabilityMiniRulesModal
	[showModal]="showMiniRules"
	modalWidth="70"
	modalHeight="80"
	(closeModalFrom2NDParent)="showMiniRules = false">
	<div class="conditions-text" *ngIf="waitingConditionsData">
		{{ "LOADING_DATA" | translate }} <br />
	</div>

	<div *ngIf="noConditionsFound">
		{{ "NO_CONDITIONS_FOUND" | translate }}
	</div>

	<div class="notice-text" *ngIf="!waitingConditionsData && !noConditionsFound">
		{{ "MINI_RULES_NOTICE" | translate }} <br />
	</div>
	
	<div
		class="mini-rules-container" *ngIf="!noConditionsFound">
		<div *ngFor="let fare of flightMiniRulesModalData">
			<div class="conditions-tittle col-md-12 col-sm-12 col-xs-12 clear-padding">
				<h5>
					<b>
						<span class="col-md-5 col-sm-5 col-xs-12 clear-padding" *ngIf="citiesDictionary">
							{{
								citiesDictionary[fare["origin"]] ? citiesDictionary[fare["origin"]]["city_name"] : fare.origin
							}}
							({{
								citiesDictionary[fare["origin"]] ? citiesDictionary[fare["origin"]]["country_name"] : ""
							}})
						</span>
						<span class="col-md-2 col-sm-2 col-xs-12 clear-padding"
							>&nbsp;<i class="fa fa-long-arrow-right" aria-hidden="true"></i
							><i class="fa fa-long-arrow-down" aria-hidden="true"></i>&nbsp;</span
						>
						<span class="col-md-5 col-sm-5 col-xs-12 clear-padding" *ngIf="citiesDictionary">
							{{
								citiesDictionary[fare.destination] ? citiesDictionary[fare.destination]["city_name"] : fare.destination
							}}
							({{
								citiesDictionary[fare.destination] ? citiesDictionary[fare.destination]["country_name"] : ""
							}})
						</span>
					</b>
				</h5>
			</div>
			<table class="mini-rules-table">
				<thead>
					<tr>
					<th></th>
					<th>{{ 'ALLOWED' | translate }}</th>
					<th>{{ 'MINIMUM AMOUNT' | translate }}</th>
					<th>{{ 'MAXIMUM AMOUNT' | translate }}</th>
					</tr>
				</thead>
				<tbody>
					<tr>
					<td>{{ 'CANCELLATION BEFORE DEPARTURE' | translate }}</td>
					<td>{{ fare.refund_before_departure_allowed ? ('YES' | translate) : ('NO' | translate) }}</td>
					<td>{{ fare.refund_before_departure_allowed ? fare.refund_before_departure_minimum_penalty + " " + fare.currency : ('NOT APPLICABLE' | translate) }}</td>
					<td>{{ fare.refund_before_departure_allowed ? fare.refund_before_departure_maximum_penalty + " " + fare.currency : ('NOT APPLICABLE' | translate) }}</td>
					</tr>
					<tr>
					<td>{{ 'CANCELLATION AFTER DEPARTURE' | translate }}</td>
					<td>{{ fare.refund_after_departure_allowed ? ('YES' | translate) : ('NO' | translate) }}</td>
					<td>{{ fare.refund_after_departure_allowed ? fare.refund_after_departure_minimum_penalty + " " + fare.currency : ('NOT APPLICABLE' | translate) }}</td>
					<td>{{ fare.refund_after_departure_allowed ? fare.refund_after_departure_maximum_penalty + " " + fare.currency : ('NOT APPLICABLE' | translate) }}</td>
					</tr>
					<tr>
					<td>{{ 'CANCELLATION BEFORE DEPARTURE NO SHOW' | translate }}</td>
					<td>{{ fare.refund_before_departure_no_show_allowed ? ('YES' | translate) : ('NO' | translate) }}</td>
					<td>{{ fare.refund_before_departure_no_show_allowed ? fare.refund_before_departure_no_show_minimum_penalty + " " + fare.currency : ('NOT APPLICABLE' | translate) }}</td>
					<td>{{ fare.refund_before_departure_no_show_allowed ? fare.refund_before_departure_no_show_maximum_penalty + " " + fare.currency : ('NOT APPLICABLE' | translate) }}</td>
					</tr>
					<tr>
					<td>{{ 'CANCELLATION AFTER DEPARTURE NO SHOW' | translate }}</td>
					<td>{{ fare.refund_after_departure_no_show_allowed ? ('YES' | translate) : ('NO' | translate) }}</td>
					<td>{{ fare.refund_after_departure_no_show_allowed ? fare.refund_after_departure_no_show_minimum_penalty + " " + fare.currency : ('NOT APPLICABLE' | translate) }}</td>
					<td>{{ fare.refund_after_departure_no_show_allowed ? fare.refund_after_departure_no_show_maximum_penalty + " " + fare.currency : ('NOT APPLICABLE' | translate) }}</td>
					</tr>
					<tr>
					<td>{{ 'REISSUE BEFORE DEPARTURE' | translate }}</td>
					<td>{{ fare.reissue_before_departure_allowed ? ('YES' | translate) : ('NO' | translate) }}</td>
					<td>{{ fare.reissue_before_departure_allowed ? fare.reissue_before_departure_minimum_penalty + " " + fare.currency : ('NOT APPLICABLE' | translate) }}</td>
					<td>{{ fare.reissue_before_departure_allowed ? fare.reissue_before_departure_maximum_penalty + " " + fare.currency : ('NOT APPLICABLE' | translate) }}</td>
					</tr>
					<tr>
					<td>{{ 'REISSUE AFTER DEPARTURE' | translate }}</td>
					<td>{{ fare.reissue_after_departure_allowed ? ('YES' | translate) : ('NO' | translate) }}</td>
					<td>{{ fare.reissue_after_departure_allowed ? fare.reissue_after_departure_minimum_penalty + " " + fare.currency : ('NOT APPLICABLE' | translate) }}</td>
					<td>{{ fare.reissue_after_departure_allowed ? fare.reissue_after_departure_maximum_penalty + " " + fare.currency : ('NOT APPLICABLE' | translate) }}</td>
					</tr>
					<tr>
					<td>{{ 'REISSUE BEFORE DEPARTURE NO SHOW' | translate }}</td>
					<td>{{ fare.reissue_before_departure_no_show_allowed ? ('YES' | translate) : ('NO' | translate) }}</td>
					<td>{{ fare.reissue_before_departure_no_show_allowed ? fare.reissue_before_departure_no_show_minimum_penalty + " " + fare.currency : ('NOT APPLICABLE' | translate) }}</td>
					<td>{{ fare.reissue_before_departure_no_show_allowed ? fare.reissue_before_departure_no_show_maximum_penalty + " " + fare.currency : ('NOT APPLICABLE' | translate) }}</td>
					</tr>
					<tr>
					<td>{{ 'REISSUE AFTER DEPARTURE NO SHOW' | translate }}</td>
					<td>{{ fare.reissue_after_departure_no_show_allowed ? ('YES' | translate) : ('NO' | translate) }}</td>
					<td>{{ fare.reissue_after_departure_no_show_allowed ? fare.reissue_after_departure_no_show_minimum_penalty + " " + fare.currency : ('NOT APPLICABLE' | translate) }}</td>
					<td>{{ fare.reissue_after_departure_no_show_allowed ? fare.reissue_after_departure_no_show_maximum_penalty + " " + fare.currency : ('NOT APPLICABLE' | translate) }}</td>
					</tr>
				</tbody>
				</table>
		</div>
		
	</div>
</app-modal>

<section>
	<div class="row page-title">
		<div class="container clear-padding text-center flight-title">
			<h3 *ngIf="pageType == 'ticket'">
				{{ "E_TICKET" | translate }}
			</h3>
			<h3 *ngIf="pageType == 'confirmation'">
				{{ "FLIGHT_CONFIRMATION" | translate }}
			</h3>
		</div>
	</div>
</section>

<!-- CheckMytrip -->
<div
	*ngIf="modalElement.booking"
	class="container confirmation-flight-header"
	style="margin-top: 35px; padding: 0">
	<div class="col-md-4 col-xs-12" style="margin-top: 2px; padding: 0">
		<span class="confirmed" style="font-size: 20px; font-weight: bold"> </span>
	</div>

	<div
		class="col-md-8 col-xs-12"
		style="text-align: right; font-size: large; padding: 0; margin-bottom: 15px">
		<a href="https://www.checkmytrip.com" target="_blank" class="actions">
			<img
				src="/assets/algerie-booking/images/checkmytrip.png"
				width="140px"
				height="40px"
				alt="check_mytrip"
				tooltip="{{ 'PRINT-CHECKTRIP' | translate }}"
				placement="top"
				container="body"
				triggers="mouseenter:mouseout" />
		</a>
		<!-- <a class="actions" (click)="goToFlightManageBookingsPage(modalElement.booking.ticket.id)">
      <i class="fa fa-pencil actions" style="color: rgb(45,50,62);" tooltip="{{'MANAGE_BOOKINGS'|translate}}"
        placement="top" container="body" triggers="mouseenter:mouseout"></i>
    </a> -->
	</div>
</div>

<!-- Alert -->
<div *ngIf="modalElement" class="container" style="padding: 0">
	<div
		class="alert alert-warning"
		role="alert"
		style="padding: 35px"
		*ngIf="modalElement.booking.status == 'PENDING'"
		[ngClass]="{ 'arabic-css': isArabic() }">
		<h4 class="alert-heading">{{ "NOTE" | translate }} !</h4>
		<p
			*ngIf="
				!modalElement.booking.optionDeadline || !modalElement.booking.optionDeadline.status
			">
			{{ "OPTION_DEADLINE1" | translate }}
		</p>
		<p
			*ngIf="
				modalElement.booking.optionDeadline && modalElement.booking.optionDeadline.status
			">
			{{ "OPTION_DEADLINE2" | translate }}: {{ modalElement.booking.optionDeadline["date"] }}
			{{ "AT" | translate }}
			{{ modalElement.booking.optionDeadline["time"] }}
		</p>
		<hr />
		<p>
			<b>{{ "OPTION_DEADLINE3" | translate }}</b>
		</p>
	</div>
</div>

<div *ngIf="modalElement.booking.ticket !== undefined">
	<div
		*ngIf="
			modalElement.booking &&
			modalElement.booking.ticket &&
			modalElement.booking.ticket.booking_need_exchange_documents &&
			modalElement.booking.status !== 'CANCELED'
		"
		class="container"
		style="padding: 0">
		<div
			class="alert alert-danger"
			role="alert"
			style="padding: 20px 35px"
			[ngClass]="{ 'arabic-css': isArabic() }">
			<h4 class="alert-heading">{{ "WARNING" | translate }} !</h4>
			<p>{{ "BOOKING_NEED_EXCHANGE_DOCUMENTS" | translate }}</p>
		</div>
	</div>
</div>

<!-- Ticket Information -->
<div class="container confirmation-body">
	<!-- 	<p class="alert alert-info">{{"IN_CASE_OF_PROBLEM" | translate}} : +213 666 66 66 66</p> -->
	<div class="row" style="padding: 35px; border: 3px dashed #bdb6b6">
		<div *ngIf="modalElement">
			<!-- Payment Information -->
			<div
				class="row"
				style="margin-bottom: 40px"
				*ngIf="modalElement.payment !== undefined && modalElement.payment !== null">
				<div class="col-md-8">
					<table border="0">
						<tr>
							<td
								align="right"
								style="padding-right: 15px; padding-bottom: 15px"
								*ngIf="
									modalElement.payment.OrderId || modalElement.payment.order_id
								">
								{{ "OrderId" | translate }} <br />
							</td>
							<td align="left" style="padding-bottom: 15px">
								<b>{{
									modalElement.payment.oderId || modalElement.payment.order_id
								}}</b
								><br />
							</td>
						</tr>
						<tr
							*ngIf="
								modalElement.payment.OrderNumber ||
								modalElement.payment.order_number
							">
							<td align="right" style="padding-right: 15px; padding-bottom: 15px">
								{{ "OrderNumber" | translate }} <br />
							</td>
							<td align="left" style="padding-bottom: 15px">
								<b>{{
									modalElement.payment.OrderNumber ||
										modalElement.payment.order_number
								}}</b
								><br />
							</td>
						</tr>
						<tr
							*ngIf="
								modalElement.payment.params &&
								modalElement.payment.params.respCode_desc
							">
							<td align="right" style="padding-right: 15px; padding-bottom: 15px">
								Réponse <br />
							</td>
							<td align="left" style="padding-bottom: 15px">
								<b
									><span style="color: green">{{
										modalElement.payment.params.respCode_desc
									}}</span></b
								><br />
							</td>
						</tr>
						<tr
							*ngIf="
								modalElement.payment.approvalCode ||
								modalElement.payment.approval_code
							">
							<td align="right" style="padding-right: 15px; padding-bottom: 15px">
								{{ "ApprovalCode" | translate }} <br />
							</td>
							<td align="left" style="padding-bottom: 15px">
								<b>{{
									modalElement.payment.approvalCode ||
										modalElement.payment.approval_code
								}}</b
								><br />
							</td>
						</tr>
						<tr>
							<td align="right" style="padding-right: 15px; padding-bottom: 15px">
								{{ "PaymentMode" | translate }} <br />
							</td>
							<td align="left" style="padding-bottom: 15px">
								<b>Carte Bancaire CIB/DAHABIA</b><br />
							</td>
						</tr>
						<tr>
							<td align="right" style="padding-right: 15px">
								{{ "REF" | translate }} <br />
								<br />
							</td>
							<td align="left">
								<b>{{ modalElement.booking.pnr }}</b
								><br />
								<span class="text-uppercase">
									<p
										[ngClass]="{
											confirmed: modalElement.booking.status == 'CONFIRMED',
											failed: modalElement.booking.status == 'CANCELED',
											failed: modalElement.booking.status == 'REFUNDED',
											pending: modalElement.booking.status == 'PENDING'
										}">
										<b>{{ modalElement.booking.status | translate }}</b>
									</p>
								</span>
							</td>
						</tr>
						<tr *ngIf="modalElement.payment.timestamp">
							<td align="right" style="padding-right: 15px; padding-bottom: 15px">
								{{ "VALIDATE_ON" | translate }} <br />
							</td>
							<td align="left" style="padding-bottom: 15px">
								<b>{{ modalElement.payment.timestamp }}</b
								><br />
							</td>
						</tr>
						<tr *ngIf="modalElement.booking.price">
							<td align="right" style="padding-right: 15px">Montant</td>
							<td align="left">
								<span class="text-uppercase">
									<p style="margin: 0">
										<b
											>{{
												formatFlightPrice(modalElement.booking.price.total)
											}}
											{{ modalElement.booking.price.currency }}</b
										>
									</p>
								</span>
							</td>
						</tr>
						<!-- <tr>
              <td align="right" style="padding-right: 15px; padding-bottom: 15px;">
                {{"VALIDATE_ON"|translate}} <br>
              </td>
              <td align="left" style="padding-bottom: 15px;">
                <b>{{ getDate() }}</b><br>              
              </td>
            </tr> -->
					</table>
				</div>
				<div class="col-md-4">
					<i class="fa fa-print"></i
					><a (click)="printReceipt()" style="cursor: pointer">
						&nbsp; Imprimez le reçu </a
					><br />
					<i class="fa fa-download"></i
					><a (click)="printReceipt()" style="cursor: pointer">
						&nbsp; Téléchargez le reçu </a
					><br />
					<i class="fa fa-envelope"></i
					><a (click)="showEmailReceiptSender()" style="cursor: pointer">
						&nbsp; Envoyer le reçu par mail </a
					><br />
					<i class="fa fa-print"></i
					><a
						style="cursor: pointer"
						(click)="
							printTicketEvent.emit({
								id: modalElement.booking.bookingKey || modalElement.bookingKey,
								mode: 'B2C'
							})
						">
						&nbsp; Imprimer le billet </a
					><br />
					<i class="fa fa-envelope"> </i>
					<a style="cursor: pointer" (click)="openEmailModal()">
						&nbsp; Envoyer le billet par email</a
					>
					<p style="margin-top: 20px; line-height: 1">
						En cas de problème avec votre carte CIB , contactez la SATIM
						<img
							src="/assets/common/images/3020.png"
							style="width: 50px; margin-left: 10px" />
					</p>
					<i class="fa fa-code"></i
					><a (click)="cryptic()" style="cursor: pointer"> &nbsp; Cryptic </a><br />
					<i class="fa fa-history"></i
					><a (click)="openHistory()" style="cursor: pointer">
						&nbsp; {{ "HISTORY" | translate }} </a
					><br />
					<p>
						{{ IN_CASE_OF_PROBLEM }}
						<img src="/assets/common/images/3020.png" style="width: 50px" />
					</p>
				</div>
			</div>

			<!-- AGENCY & CLIENT INFORMATION -->
			<div
				*ngIf="
					modalElement &&
					modalElement.booking &&
					modalElement.booking.ticket &&
					modalElement.booking.ticket.agency &&
					!modalElement.payment
				"
				class="row"
				style="margin-bottom: 20px">
				<div class="col-md-2" style="text-align: center">
					<img
						[src]="imagesURL + modalElement.booking.ticket.agency.logo"
						alt="agency_logo"
						style="height: auto; width: 120px"
						*ngIf="modalElement.booking.ticket.agency.logo != null" />
				</div>
				<div
					class="col-md-4"
					style="border-left: solid 1px #999999"
					*ngIf="modalElement.booking.ticket">
					{{ modalElement.booking.ticket.agency.name }} <br />
					{{ modalElement.booking.ticket.agency.address }} <br />
					Tel : {{ modalElement.booking.ticket.agency.phone_number }}<br />
					@ : {{ modalElement.booking.ticket.agency.email }}
				</div>
				<div class="col-md-2 text-uppercase" style="vertical-align: middle">
					{{ "CLIENT" | translate }}
				</div>
				<div
					class="col-md-4"
					style="border-left: solid 1px #999999"
					*ngIf="modalElement.booking.ticket">
					{{ modalElement.booking.ticket.customer.name }}<br />
					{{ modalElement.booking.ticket.customer.address }} <br />
					Tel : {{ modalElement.booking.ticket.customer.phone_number }} <br />
					@ :
					{{ modalElement.booking.ticket.customer.email }}
				</div>
			</div>

			<div class="clearfix"></div>

			<div>{{ "REFERENCE" | translate }} : {{ modalElement.booking.pnr }}</div>
			<div
				[ngClass]="{
					confirmed: modalElement.booking.status == 'CONFIRMED',
					failed: modalElement.booking.status == 'CANCELED',
					pending: modalElement.booking.status == 'PENDING'
				}">
				{{ "STATUS" | translate }} : {{ modalElement.booking.status | translate }}
			</div>

			<div style="height: 40px; width: 100%"></div>

			<!-- PASSAGER INFORMATION CONFIRMATION -->
			<table
				class="table_fixed"
				*ngIf="modalElement && pageType == 'confirmation'"
				style="width: 100%">
				<tr style="border-bottom: solid 1px slategrey">
					<td class="tableCell uk-text-bold">{{ "PASSAGER" | translate }}</td>
					<td class="tableCell uk-text-bold">{{ "PAX_TYPE" | translate }}</td>
					<td class="tableCell uk-text-bold">{{ "STATUT" | translate }}</td>
					<td class="tableCell uk-text-bold">{{ "N_TICKET" | translate }}</td>
				</tr>
				<tr *ngFor="let etkt of modalElement.booking.ticketNumbers">
					<td class="tableCell">
						{{ etkt.firstname }}&nbsp;{{ etkt.middlename }}&nbsp;{{ etkt.lastname }}
					</td>
					<td class="tableCell">
						{{ etkt.type }}
					</td>
					<td
						class="tableCell"
						[ngClass]="{
							confirmed: etkt.status == 'CONFIRMED',
							failed: etkt.status == 'CANCELED',
							pending: etkt.status == 'PENDING'
						}">
						{{ etkt.status | translate }}
					</td>
					<td class="tableCell">
						{{ etkt.ticket }}
					</td>
				</tr>
			</table>

			<!-- PASSAGER INFORMATION TICKET -->
			<table
				class="table_fixed"
				*ngIf="modalElement && pageType == 'ticket' && modalElement.booking.ticket.etickets"
				style="width: 100%">
				<tr style="border-bottom: solid 1px slategrey">
					<td class="tableCell uk-text-bold">{{ "PASSAGER" | translate }}</td>
					<td class="tableCell uk-text-bold">{{ "PAX_TYPE" | translate }}</td>
					<td class="tableCell uk-text-bold">{{ "STATUT" | translate }}</td>
					<td class="tableCell uk-text-bold">{{ "N_TICKET" | translate }}</td>
				</tr>
				<tr *ngFor="let etkt of modalElement.booking.ticket.etickets">
					<td class="tableCell">
						{{ etkt.pax_name }}
					</td>
					<td class="tableCell">
						{{ etkt.type }}
					</td>
					<td
						class="tableCell"
						[ngClass]="{
							confirmed: etkt.status == 'CONFIRMED',
							failed: etkt.status == 'CANCELED',
							pending: etkt.status == 'PENDING'
						}">
						{{ (etkt.status | translate) || modalElement.booking.status }}
					</td>
					<td class="tableCell">
						{{ etkt.ticket_number }}
					</td>
				</tr>
			</table>

			<table
				class="table_fixed"
				*ngIf="modalElement && pageType == 'ticket' && modalElement.booking.ticketNumbers"
				style="width: 100%">
				<tr style="border-bottom: solid 1px slategrey">
					<td class="tableCell uk-text-bold">{{ "PASSAGER" | translate }}</td>
					<td class="tableCell uk-text-bold">{{ "PAX_TYPE" | translate }}</td>
					<td class="tableCell uk-text-bold">{{ "STATUT" | translate }}</td>
					<td class="tableCell uk-text-bold">{{ "N_TICKET" | translate }}</td>
				</tr>
				<tr *ngFor="let etkt of modalElement.booking.ticketNumbers">
					<td class="tableCell">
						{{ etkt.pax_name }}
					</td>
					<td class="tableCell">
						{{ etkt.type }}
					</td>
					<td
						class="tableCell"
						[ngClass]="{
							confirmed: etkt.status == 'CONFIRMED',
							failed: etkt.status == 'CANCELED',
							pending: etkt.status == 'PENDING'
						}">
						{{ etkt.status | translate }}
					</td>
					<td class="tableCell">
						{{ etkt.ticket_number }}
					</td>
				</tr>
			</table>

			<div style="height: 40px; width: 100%"></div>
			<table
				class="table_fixed"
				*ngIf="modalElement && modalElement.booking.price.breakdown; else elseBlock"
				style="width: 100%">
				<tr style="border-bottom: solid 1px slategrey">
					<td class="tableCell uk-text-bold">{{ "AIRPORTS" | translate }}</td>
					<td class="tableCell uk-text-bold">{{ "PAX_TYPE" | translate }}</td>
					<td class="tableCell uk-text-bold">{{ "BAGGAGE" | translate }}</td>
				</tr>
				<tr *ngFor="let bound of modalElement.booking.bounds; let boundIndex = index">
					<td class="tableCell">
						<span *ngIf="airportDictionary">
							{{
								airportDictionary[bound["origin"]]
									? airportDictionary[bound["origin"]]["city_name"]
									: bound.origin
							}}
							->
							{{
								airportDictionary[bound["destination"]]
									? airportDictionary[bound["destination"]]["city_name"]
									: bound["destination"]
							}}
						</span>
					</td>
					<td class="tableCell">
						<span *ngFor="let type of modalElement.booking.price.breakdown | keyvalue">
							<span style="display: block">
								{{ type.key | translate }}
							</span>
						</span>
					</td>
					<td class="tableCell">
						<span *ngFor="let type of modalElement.booking.price.breakdown | keyvalue">
							<span style="display: block">
								<b>{{ type.value["baggageAllowance"][boundIndex] }}</b>
							</span>
						</span>
					</td>
				</tr>
			</table>
			<ng-template #elseBlock>
				<table
					class="table_fixed"
					*ngIf="modalElement && modalElement.booking.bounds"
					style="width: 100%">
					<tr style="border-bottom: solid 1px slategrey">
						<td class="tableCell uk-text-bold">{{ "AIRPORTS" | translate }}</td>
						<td class="tableCell uk-text-bold">{{ "PAX_TYPE" | translate }}</td>
						<td class="tableCell uk-text-bold">{{ "BAGGAGE" | translate }}</td>
					</tr>
					<tr *ngFor="let bound of modalElement.booking.bounds; let boundIndex = index">
						<td class="tableCell">
							<b
								>{{ bound.departure_location.city_and_country }} ->
								{{ bound.arrival_location.city_and_country }}</b
							>
						</td>
						<td class="tableCell">
							<span style="display: block" *ngIf="bound.segments[0].baggage_info[0]">
								<b>{{ bound.segments[0].baggage_info[0].pax_type | translate }}</b>
							</span>
						</td>
						<td class="tableCell">
							<span style="display: block" *ngIf="bound.segments[0].baggage_info[0]">
								<b>{{ bound.segments[0].baggage_info[0].allowance }}</b>
							</span>
						</td>
					</tr>
				</table>
			</ng-template>

			<!-- BOUND INFORMATION -->
			<div *ngIf="modalElement.booking.ticket" class="itinerary-details text-center">
				<div
					*ngFor="
						let bound of modalElement.booking.ticket.bounds;
						let boundIndex = index
					">
					<div
						class="bound_title"
						data-toggle="collapse"
						[id]="'boundParent' + boundIndex"
						[attr.href]="'#bound' + boundIndex"
						role="button"
						aria-expanded="false"
						[attr.aria-controls]="'#bound' + boundIndex">
						<div class="bound_title_content">
							<i class="fa fa-plane bound_title_content_icon"></i>
							<div>
								{{ "FROM" | translate }}
								<b>{{ bound.departure_location.city_and_country }}</b>
								{{ "TO" | translate }}
								<b>{{ bound.arrival_location.city_and_country }}</b>
							</div>
						</div>
					</div>
					<div
						class="collapse show"
						[attr.data-parent]="'#boundParent' + boundIndex"
						[id]="'bound' + boundIndex">
						<div class="flight" *ngFor="let s of bound.segments; let i_s = index">
							<div class="fromToBar col-md-12 col-sm-12 col-xs-12">
								<div class="col-md-6 col-sm-6 col-xs-12 destination-title-left">
									{{ "FROM" | translate }}
									<b>{{
										s.departure_location["city_and_country"] != ""
											? s.departure_location["city_and_country"]
											: s.departure_location["airport"]
									}}</b>
									{{ "TO" | translate }}
									<b>{{
										s.arrival_location["city_and_country"] != ""
											? s.arrival_location["city_and_country"]
											: s.arrival_location["airport"]
									}}</b>
								</div>

								<div
									class="col-md-6 col-sm-6 col-xs-12 destination-title-right"
									*ngIf="
										i_s - 1 <
											modalElement.booking.ticket.bounds[boundIndex].segments
												.length -
												1 && i_s > 0
									">
									<i class="fa fa-clock-o"></i> {{ "WAITING" | translate }}&nbsp;
									{{
										timeDifference(
											modalElement.booking.ticket.bounds[boundIndex].segments[
												i_s - 1
											].arrival_date,
											modalElement.booking.ticket.bounds[boundIndex].segments[
												i_s - 1
											].arrival_time,
											modalElement.booking.ticket.bounds[boundIndex].segments[
												i_s
											].departure_date,
											modalElement.booking.ticket.bounds[boundIndex].segments[
												i_s
											].departure_time
										)
									}}
								</div>
							</div>

							<div class="col-md-2 col-sm-2 col-xs-12">
								<img [src]="airlineLogoAddress + s.carrier + fileExt" alt="image" />
								<h6 *ngIf="pageType == 'confirmation'">
									{{ airlineDictionary[s.carrier].airline }}
								</h6>
								<h6 *ngIf="pageType == 'ticket'">
									{{ modalElement.booking.ticket["airlines"][s.carrier].airline }}
								</h6>
							</div>
							<div class="col-md-3 col-sm-3 col-xs-12">
								<h5 style="font-weight: bold">
									{{
										s.departure_location["city_and_country"] != ""
											? s.departure_location["city_and_country"]
											: s.departure_location["ariport"]
									}}
								</h5>
								<h5 style="margin-top: -5px" *ngIf="s.departure_time">
									{{ s.departure_date }} {{ "AT" | translate }}
									{{ s.departure_time }}
								</h5>
								<h6 style="margin-top: -5px">
									{{ s.departure_location["airport"] }}
								</h6>
							</div>
							<div class="col-md-1 col-sm-1 col-xs-12 text-center">
								<i class="fa fa-long-arrow-right" style="padding-top: 30px"></i>
								<i class="fa fa-long-arrow-down" style="padding-top: 30px"></i>
							</div>
							<div class="col-md-3 col-sm-3 col-xs-12">
								<h5 style="font-weight: bold">
									{{
										s.arrival_location["city_and_country"] != ""
											? s.arrival_location["city_and_country"]
											: s.arrival_location["airport"]
									}}
								</h5>
								<h5 style="margin-top: -5px" *ngIf="s.arrival_time">
									{{ s.arrival_date }} {{ "AT" | translate }} {{ s.arrival_time }}
								</h5>
								<h6 style="margin-top: -5px">
									{{ s.arrival_location["airport"] }}
								</h6>
							</div>
							<div class="col-md-2 col-sm-3 col-xs-12" style="text-align: left">
								<h6>
									{{
										classTitles[classCorrespondances.indexOf(s.booking_class)]
											| translate
									}}
								</h6>
								<h6>{{ "BOOKING CLASS" | translate }} {{ s.booking_class }}</h6>
								<h6>
									<span *ngIf="s.flight_number == 'OPEN'">{{
										"OPEN_FLIGHT" | translate
									}}</span>
									<span *ngIf="s.flight_number != 'OPEN'"
										>{{ "FLIGHT_NUMBER" | translate }} {{ s.carrier
										}}{{ s.flight_number }}</span
									>
								</h6>
							</div>
						</div>
					</div>
				</div>
				<div class="clearfix"></div>

				<!-- hotel search -->
				<!-- <app-flight-hotel-search-shared *ngIf="modalElement && iataCode && airportDictionary && (tripType=='rt')"
          [iataCode]="iataCode" [airportDictionary]="airportDictionary" [checkIn]="checkIn" [checkOut]="checkOut"
          [searchSource]="'flight'">
        </app-flight-hotel-search-shared> -->
				<!-- end hotel search -->

				<div class="clearfix"></div>
				<div class="row">
					<div class="col-md-6"></div>
					<div class="col-md-6">
						<table width="100%" style="margin-top: 20px">
							<tr
								*ngFor="
									let history of modalElement.booking.history;
									let historyIndex = index
								">
								<td
									class="tableCellNormal text-right"
									*ngIf="history.type == 'PAYMENT' && history.price">
									<b>
										{{ "PAYMENT" | translate }}
										&nbsp; &nbsp;
										<span *ngIf="history.date"
											>({{ history.date.split(" ")[0] }})</span
										>
									</b>
								</td>
								<td
									width="40%"
									class="tablePrices text-left"
									*ngIf="history.type == 'PAYMENT' && history.price">
									{{ formatFlightPrice(history.price.total) }}&nbsp;{{
										history.price.currency
									}}
								</td>

								<td
									class="tableCellNormal text-right"
									*ngIf="history.type == 'REFUND' && history.refund">
									<b>
										{{ "REFUND" | translate }}
										&nbsp;
										<span *ngIf="history.date"
											>({{ history.date.split(" ")[0] }})</span
										>
									</b>
								</td>
								<td
									width="40%"
									class="tablePrices text-left failed"
									*ngIf="history.type == 'REFUND' && history.refund">
									- {{ formatFlightPrice(history.refund.total) }}&nbsp;{{
										history.refund.currency
									}}
								</td>
							</tr>
							<tr>
								<td class="tableCellNormal text-right">
									<b>{{ "TOTAL" | translate }}</b>
								</td>
								<td width="40%" class="tablePrices text-left">
									{{ formatFlightPrice(modalElement.booking.ticket.price.total) }}
									{{ modalElement.booking.ticket.price.currency }}
								</td>
							</tr>
						</table>
					</div>
				</div>
			</div>

			<!-- only for ePayment -->
			<div
				*ngIf="
					modalElement.booking.bounds &&
					modalElement.payment !== undefined &&
					!modalElement.isEditTicket
				"
				class="itinerary-details text-center">
				<div *ngFor="let bound of modalElement.booking.bounds; let boundIndex = index">
					<div class="flight" *ngFor="let s of bound.segments; let i_s = index">
						<div class="fromToBar col-md-12 col-sm-12 col-xs-12">
							<div class="col-md-6 col-sm-6 col-xs-12 destination-title-left">
								{{ "FROM" | translate }} <b>{{ s.originDestination }}</b>
								{{ "TO" | translate }} <b>{{ s.arrivalDestination }}</b>
							</div>

							<div
								class="col-md-6 col-sm-6 col-xs-12 destination-title-right"
								*ngIf="
									i_s - 1 <
										modalElement.booking.bounds[boundIndex].segments.length -
											1 && i_s > 0
								">
								<i class="fa fa-clock-o"></i> {{ "WAITING" | translate }}&nbsp;
								{{
									timeDifference(
										modalElement.booking.bounds[boundIndex].segments[i_s - 1]
											.arrivalDate,
										modalElement.booking.bounds[boundIndex].segments[i_s - 1]
											.arrivalTime,
										modalElement.booking.bounds[boundIndex].segments[i_s]
											.departureDate,
										modalElement.booking.bounds[boundIndex].segments[i_s]
											.departureTime
									)
								}}
							</div>
						</div>

						<div class="col-md-2 col-sm-2 col-xs-12">
							<img
								*ngIf="s.carrier"
								[src]="airlineLogoAddress + s.carrier + fileExt"
								alt="image" />
							<img
								*ngIf="!s.carrier"
								[src]="
									airlineLogoAddress +
									modalElement.booking.validatingCarrier +
									fileExt
								"
								alt="image" />
							<h6 *ngIf="(airlineDictionary | keyvalue)?.length; else airlineCode">
								{{ airlineDictionary[s.carrier].airline }}
							</h6>
							<ng-template #airlineCode>
								<h6>{{ modalElement.booking.validatingCarrier }}</h6>
							</ng-template>
						</div>
						<div class="col-md-3 col-sm-3 col-xs-12">
							<h5 style="font-weight: bold">{{ s.originDestination }}</h5>
							<h5 style="margin-top: -5px" *ngIf="s.departureTime">
								{{ s.departureDate }} {{ "AT" | translate }} {{ s.departureTime }}
							</h5>
							<!-- <h6 style="margin-top : -5px;">{{s.departure_location["airport"]}}</h6> -->
						</div>
						<div class="col-md-1 col-sm-1 col-xs-12 text-center">
							<i class="fa fa-long-arrow-right" style="padding-top: 30px"></i>
							<i class="fa fa-long-arrow-down" style="padding-top: 30px"></i>
						</div>
						<div class="col-md-3 col-sm-3 col-xs-12">
							<h5 style="font-weight: bold">{{ s.arrivalDestination }}</h5>
							<h5 style="margin-top: -5px" *ngIf="s.arrivalTime">
								{{ s.arrivalDate }} {{ "AT" | translate }} {{ s.arrivalTime }}
							</h5>
							<!-- <h6 style="margin-top : -5px;">{{s.arrival_location["airport"]}}</h6> -->
						</div>
						<div class="col-md-2 col-sm-3 col-xs-12" style="text-align: left">
							<!-- TODO find cabin in the response <h6>{{"ECONOMY_STANDARD"|translate}}</h6>-->
							<h6>{{ "BOOKING CLASS" | translate }} {{ s.bookingClass }}</h6>
							<h6>
								<span *ngIf="s.flightNumber == 'OPEN'">{{
									"OPEN_FLIGHT" | translate
								}}</span>
								<span *ngIf="s.flightNumber != 'OPEN'">
									{{ "FLIGHT_NUMBER" | translate }}
									{{ modalElement.booking.validatingCarrier }}{{ s.flightNumber }}
								</span>
							</h6>
						</div>
					</div>
				</div>
				<div class="clearfix"></div>

				<!-- hotel search -->
				<!-- <app-flight-hotel-search-shared *ngIf="modalElement && iataCode && airportDictionary && (tripType=='rt')"
          [iataCode]="iataCode" [airportDictionary]="airportDictionary" [checkIn]="checkIn" [checkOut]="checkOut"
          [searchSource]="'flight'">
        </app-flight-hotel-search-shared> -->
				<!-- end hotel search -->

				<div class="clearfix"></div>
				<div class="row">
					<div class="col-md-6"></div>
					<div class="col-md-6">
						<table width="100%" style="margin-top: 20px">
							<tr>
								<td width="60%" class="tableCellNormal text-right">
									{{ "PAYMENT" | translate }} missing the date
								</td>
								<td width="40%" class="tablePrices text-left">
									missing payment {{ modalElement.booking.price.currency }}
								</td>
							</tr>
							<tr>
								<td class="tableCellNormal text-right">
									{{ "REFUND" | translate }}
								</td>
								<td width="40%" class="tablePrices text-left">
									missing refund {{ modalElement.booking.price.currency }}
								</td>
							</tr>
							<tr>
								<td class="tableCellNormal text-right">
									{{ "PENALTY" | translate }}
								</td>
								<td width="40%" class="tablePrices text-left">
									missing penality {{ modalElement.booking.price.currency }}
								</td>
							</tr>
							<tr>
								<td class="tableCellNormal text-right">
									{{ "PROMOTION" | translate }}
								</td>
								<td width="40%" class="tablePrices text-left">
									missing promotion {{ modalElement.booking.price.currency }}
								</td>
							</tr>
							<tr>
								<td class="tableCellNormal text-right" height="20px"></td>
							</tr>
							<tr>
								<td class="tableCellNormal text-right">
									<b>{{ "TOTAL" | translate }}</b>
								</td>
								<td width="40%" class="tablePrices text-left">
									<b
										>{{ formatFlightPrice(modalElement.booking.price.total) }}
										{{ modalElement.booking.price.currency }}</b
									>
								</td>
							</tr>
						</table>
					</div>
				</div>
			</div>
		</div>
	</div>
</div>

<!-- Floating Buttons -->
<div *ngIf="isB2BUser()" class="floating-buttons">
	<div class="tile">
		<div (click)="toggleButtons()" class="float menu-share">
			<i class="fa my-float" [ngClass]="showButtons ? 'fa-times' : 'fa-ellipsis-v'"></i>
		</div>
		<div class="soc">
			<a
				class="print"
				(click)="printTicketEvent.emit({ id: modalElement.booking.ticket.id, mode: 'B2B' })"
				tooltip="{{ 'PRINT_DETAILS' | translate }}"
				placement="top"
				container="body"
				triggers="mouseenter:mouseout">
				<i class="fa fa-print"></i>
			</a>
			<a class="mail" (click)="openEmailModal()">
				<i
					class="fa fa-envelope"
					tooltip="{{ 'SEND-BY-EMAIL' | translate }}"
					placement="top"
					container="body"
					triggers="mouseenter:mouseout"></i>
			</a>
			<a class="history" (click)="openHistory()">
				<i
					class="fa fa-history"
					tooltip="{{ 'HISTORY' | translate }}"
					placement="top"
					container="body"
					triggers="mouseenter:mouseout"></i>
			</a>

			<a
				*ngIf="getConfirmationButtonConditions()"
				class="validate"
				(click)="openConfirmModal('CONFIRM_BOOKING')">
				<i
					class="fa fa-check"
					tooltip="{{ 'CONFIRM_BOOKING' | translate }}"
					placement="top"
					container="body"
					triggers="mouseenter:mouseout"></i>
			</a>

			<a
				*ngIf="getRefreshButtonConditions() && modalElement?.booking?.ticket?.id"
				class="refresh"
				(click)="openConfirmModal('REFRESH')">
				<i
					class="fa fa-spinner"
					tooltip="{{ 'REFRESH' | translate }}"
					placement="top"
					container="body"
					triggers="mouseenter:mouseout"></i>
			</a>

			<a
				*ngIf="getSynchronizeButtonConditions()"
				class="sync"
				(click)="openConfirmModal('SYNCHRONIZE')">
				<i
					class="fa fa-refresh"
					tooltip="{{ 'SYNCHRONIZE' | translate }}"
					placement="top"
					container="body"
					triggers="mouseenter:mouseout"></i>
			</a>

			<a
				*ngIf="getTransfertButtonConditions()"
				class="transfert"
				(click)="transfertTicketEvent.emit()">
				<i
					class="fa fa-exchange"
					tooltip="{{ 'TRANSFER' | translate }}"
					placement="top"
					container="body"
					triggers="mouseenter:mouseout"></i>
			</a>

			<a class="cryptic" (click)="cryptic()">
				<i
					class="fa fa-code"
					tooltip="Cryptic"
					placement="top"
					container="body"
					triggers="mouseenter:mouseout"></i>
			</a>

			<a class="mini-rules" (click)="openMiniRules()">
				<i
					class="fa fa-list-alt"
					tooltip="Conditions"
					placement="top"
					container="body"
					triggers="mouseenter:mouseout"></i>
			</a>

			<!-- to be cleaned
        <a *ngIf="getRefundButtonConditions()" class="refund" (click)="openConfirmModal('REFUND')" >
        <i class="fa fa-bank"  tooltip="{{'REFUND'|translate}}" placement="top" container="body" triggers="mouseenter:mouseout"></i>
      </a>-->

			<a
				*ngIf="getCancelButtonConditionsAdmin()"
				class="cancel_admin"
				(click)="openCancelAdminModal()">
				<i
					class="fa fa-close"
					tooltip="{{ 'REFUND' | translate }}"
					placement="top"
					container="body"
					triggers="mouseenter:mouseout"></i>
			</a>

			<a
				*ngIf="getCancelButtonConditions()"
				class="cancel"
				(click)="openConfirmModal('CANCEL')">
				<i
					class="fa fa-close"
					tooltip="{{ 'CANCEL' | translate }}"
					placement="top"
					container="body"
					triggers="mouseenter:mouseout"></i>
			</a>
		</div>
	</div>
</div>
